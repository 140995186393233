import React from "react";
import { TypeAnimation } from "react-type-animation";

const App = () => {
  return (
    <div className="bg-portfolio bg-cover text-white h-screen flex flex-col text-center justify-center">
      <h1 className="text-4xl mb-4">Florian Lescribaa</h1>
      <h2 className="text-3xl mb-8">Développeur Web</h2>
      <TypeAnimation
        className="text-xl"
        sequence={[
          "En cours de développement...",
          1500,
          "Ça arrive bientôt...",
          1500,
          "Soit patient...",
          1500,
          "Repasse plus tard. Ça sera probablement terminé...",
          1000,
        ]}
        wrapper="p"
        speed={25}
        repeat={Infinity}
      />
    </div>
  );
};

export default App;
